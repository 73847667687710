import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";
import Button from "../components/Button/Button";

import "./AboutUsPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const AboutUsPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto", minHeight: "60vh" }}>
        <Section>
          <SectionHeading>About Us | AI Product Reviews</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              Welcome to AI Product Reviews, where we are dedicated to providing
              comprehensive evaluations of every AI tool on the market. Our
              mission is to guide you through the rapidly evolving landscape of
              artificial intelligence, ensuring you make informed decisions for
              your unique business needs.
            </p>
            <p>
              Discover our mission and the dedicated team behind AI Product
              Reviews. We're dedicated to honest and insightful reviews.
            </p>
            <h3>Our Mission</h3>
            <p>
              Here at AI Product Reviews, our mission is very simple: to make
              information about AI products on the ever-expanding market
              accessible to all. We provide unbiased and up-to-date reviews and
              product guides for AI tools as they are released so you can make
              informed choices about which AI platforms to bring into your
              business operations.
            </p>
            <h3>Our Story</h3>
            <p>
              Founded by young entrepreneur Pete, whose keen enterprising spirit
              has driven him through various business ventures, AI Product
              Reviews represents the culmination of Pete&rsquo;s passion for
              cutting-edge technology and innovation. Partnering with Max, these
              trailblazers are embarking on a journey to demystify the world of
              AI tools, drawing on their collective expertise to offer unbiased
              insights in accessible and informative AI product reviews and
              how-to guides.
            </p>
            <h3>Our Responsibility &amp; Guarantee</h3>
            <p>
              Transparency and integrity are at the core of our operations. We
              rely on affiliate links to sustain our rigorous AI product testing
              and reviewing. Rest assured, our guides and reviews are rooted in
              evidence and ethical considerations. We prioritize genuine product
              experiences and provide direct, no-nonsense comparisons to empower
              your decision-making process.
            </p>
            <h3>Connect With Us</h3>
            <p>
              We invite you to be part of our community. Whether through
              insightful comments, engaging social media discussions, or by
              reaching out to us at{" "}
              <a
                href="mailto:info@ai-productreviews.com"
                target="_blank"
                rel="noopener"
              >
                info@ai-productreviews.com
              </a>
              , let's navigate the AI landscape together. Your support allows us
              to continue evaluating products without resorting to intrusive
              ads. Join us in pioneering the future of AI technology.
            </p>
            <div className="about-us-profiles">
              <div className="about-us-profile">
                <div className="about-us-profile-photo">
                  <img src="/images/peter-king.png" />
                </div>
                <h3>Peter King</h3>
                <p>
                  Pete is a 30 year old entrepreneur with a passion and natural
                  affinity for online businesses. He started his first business
                  venture at the age of 13 buying and selling drums. Since then,
                  Pete has gone on to start a number of successful online
                  businesses from the age of 18 to the present day. With this
                  wealth of online experience under his belt, Pete has had
                  multiple successful exits over the last 5 years.&nbsp;
                </p>
                <p>
                  Pete&rsquo;s interests now lie within the world of AI. He is
                  keen to see how businesses&rsquo; operational procedures
                  change with the help of AI and how AI software evolves over
                  the next 10 years. Pete believes it is an exciting time in the
                  digital space and that sharp business minds should embrace the
                  change and leverage AI to streamline business processes and
                  make their lives easier.
                </p>
                <a href="/peter-king">
                  <Button>View profile</Button>
                </a>
              </div>
              <div className="about-us-profile">
                <div className="about-us-profile-photo">
                  <img src="/images/max-maybury.png" />
                </div>
                <h3>Max Maybury</h3>
                <p>
                  Max is a young businessman and the developer of the AI Product
                  Reviews platform. His entrepreneurial spirit and desire to
                  know as much about AI tech as possible make Max the perfect
                  fit for this project. Partnering with Pete, Max intends to
                  uncover a world of AI platforms that can revolutionize the way
                  businesses are run and managed.
                </p>
                <p>
                  Technology and smart business solutions have always been
                  interests for Max, and now he is using that expertise and
                  enthusiasm to help us review AI tools and provide suggestions
                  for future tools to review. With his keen understanding of
                  technology and AI, Max intends to help foster an environment
                  on the AI Product Reviews site of transparent, honest,
                  technical reviews that help people to make informed decisions.
                </p>
                <a href="/max-maybury">
                  <Button>View profile</Button>
                </a>
              </div>
            </div>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default AboutUsPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"About Us | AI Product Reviews"}
      description={
        "Discover our mission and team behind AI Product Reviews. We're dedicated to honest and insightful reviews."
      }
      pathname={location.pathname}
    />
  );
};
